<template>
    <custom-table
        :filter_settings="blogFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :filters="filters"
        :headers_items="headerItems"
        :toolbar_settings="toolbarSettings"
        :links="links"
        :loading="loading || !blogs"
        :content-type="contentType"
        :items="blogs"
        :options="tableOptions"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/blog-page/content-table-header-items'
import { FilterItems, Filters } from '@/helpers/constants/blog-page/filters'
import { toolbarSettings } from '@/helpers/constants/blog-page/toolbar-settings'
import { blogFilters } from '@/helpers/filters-settings/blog-filters'
import { Links } from '@/helpers/constants/blog-page/links'

import {
    ACTION_GET_BLOG_LIST
} from '@/store/modules/content/content-list/action-types'

import { mapContentListFields } from '@/store/modules/content/content-list'

import {
    ACTION_GET_CATEGORY_LIST_FOR_SELECT
} from '@/store/modules/content/content-category/action-types'

import { createNamespacedHelpers } from 'vuex';
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('contentList');

const {
    mapActions: mapContentСategoriesActions
} = createNamespacedHelpers('contentCategory');

export default {
    name: 'IndexVue',
    mixins: [
        contentBaseMixin
    ],
    props: {
        contentType: String,
        classes: String
    },
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: true
            },
            currentRoute: 'posts-list-blog',
            filters: {}
        }
    },
    computed: {
        ...mapContentListFields([
            'blogs'
        ]),
        blogFilters() {
            return blogFilters
        },
        headerItems() {
            return headerItems
        },
        filterItems() {
            return FilterItems
        },
        allToolbarSettings() {
            return toolbarSettings
        },
        items() {
            return this.blogs
        },
        links() {
            return Links
        },
        page_filters() {
            return Filters
        }
    },
    watch: {
        '$route.query': debounce(function (val) {
            if (this.$route.name !== 'posts-list-blog') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            this.getList(val)
        }, 50)
    },
    activated() {
        this[ACTION_GET_CATEGORY_LIST_FOR_SELECT]({
            path: 'blog'
        })
        // this.getList(this.filters)
    },
    methods: {
        ...mapContentСategoriesActions([
            ACTION_GET_CATEGORY_LIST_FOR_SELECT
        ]),
        ...mapContentListActions([
            ACTION_GET_BLOG_LIST
        ]),
        getList(query) {
            this[ACTION_GET_BLOG_LIST]({ ...query })
        }
    }
}
</script>
