export const headerItems = [
    {
        sort_by: 'checkbox',
        styles: 'width:40px'
    },
    {
        sort_by: 'index'
    },
    {
        classes: 'custom-table__row-status',
        sort_by: 'published',
        styles: 'justify-content: center',
        title: 'Status'
    },
    {
        classes: 'custom-table__row-url',
        styles: 'width: 23%; display: flex; justify-content: flex-start;',
        sort_by: 'post_slug',
        title: 'Url'
    },
    {
        classes: 'custom-table__row-wordcount',
        sort_by: 'wordcount',
        styles: 'justify-content: center',
        title: 'Words'
    },
    {
        classes: 'custom-table__row-locale',
        styles: 'justify-content: center',
        sort_by: 'locale',
        title: 'Locale'
    },
    {
        classes: 'custom-table__row-created-at',
        styles: 'justify-content: center',
        sort_by: 'created_at',
        title: 'Created'
    },
    {
        classes: 'custom-table__row-updated-at',
        styles: 'justify-content: center',
        sort_by: 'updated_at',
        title: 'Updated'
    },
    {
        classes: 'custom-table__row-updated-by',
        styles: 'justify-content: center',
        sort_by: 'updated_by',
        title: 'Updated By'
    },
    {
        classes: 'custom-table__row-revisions',
        styles: 'justify-content: center',
        sort_by: 'revisions_count',
        title: 'Rev.'
    },
    {
        classes: 'custom-table__row-reviews',
        styles: 'justify-content: center',
        sort_by: 'reviews_count',
        title: 'Reviews',
        hide_sort: true
    },
    {
        classes: 'custom-table__row-in-menu',
        styles: 'justify-content: center',
        sort_by: 'locked',
        title: 'Locked'
    }
]
